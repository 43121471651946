



























































































































































import { formatDate, xmlToJson } from '@/helpers';
import { FileService } from '@/services/file.service';
import { Component, Prop, Vue, Watch } from 'vue-property-decorator';

import { ArrayOfUplouderResponseXml, OutputStatus, UplouderResponse } from '@/models/xml';
import { Soubor } from '@/models';
import { ModAppGetter } from '@/store/app/app.getter';

@Component
export default class PrilohyComponent extends Vue {
    @Prop() value!: Soubor[];
    @Prop() readonly!: boolean;

    isUploading = false;

    readonly service = new FileService();

    soubory: Soubor[] = [];

    @Watch('value', { immediate: true })
    onChangeValue(): void {
        this.soubory = [...this.value];
    }

    fileUploadDialog = false;

    files: File[] = [];
    uploaderResponse: UplouderResponse[] | null = null;

    getStatus(status: string): string {
        switch (status) {
            case OutputStatus.SavedOK.toString():
                return 'Nahráno';
            case OutputStatus.Forbidden.toString():
                return 'Neproběhlo';
            default:
                return '';
        }
    }

    getUrl(cesta: string): string {
        return `${this.$store.getters[ModAppGetter.FILEURL]}/uloziste/${cesta.substring(0, 2)}/${cesta}`;
    }

    getStatusResult(status: string): boolean {
        return status == OutputStatus.SavedOK.toString();
    }

    formatDate(date: string) {
        return formatDate(date, 'dd. MM. yyyy HH:mm');
    }

    add() {
        this.fileUploadDialog = true;
        this.uploaderResponse = null;
        this.files = [];
    }

    addFiles(e: InputEvent) {
        (e.target as any)?.files?.forEach((file: File) => {
            this.files.push(file);
        });

        (this.$refs.fileInput as any).value = '';
    }

    dropFiles(e: DragEvent) {
        const droppedFiles = e.dataTransfer?.files;
        if (!droppedFiles) return;

        [...droppedFiles].forEach(f => {
            this.files.push(f);
        });
    }

    removeFile(file: File) {
        this.files = this.files.filter(f => f !== file);
    }

    close() {
        if (this.uploaderResponse?.length) {
            const prilohy: Soubor[] = this.uploaderResponse
                .filter(f => this.getStatusResult(f.OutputStatus))
                .map(m => {
                    return {
                        tmpId: new Date().getTime(),
                        nazev: m.FileName,
                        cesta: m.NewFileGuid,
                        vytvoreno: new Date()
                    };
                });

            this.$emit('input', [...this.soubory, ...prilohy]);
        }

        this.fileUploadDialog = false;
        this.uploaderResponse = null;
        this.files = [];
    }

    async upload() {
        this.isUploading = true;

        const formData = new FormData();
        this.files.forEach(file => formData.append('file', file, file.name));

        try {
            const res = await this.service.fileUpload(formData);
            const parser = new DOMParser();
            const xmlDoc = parser.parseFromString(res.data, 'text/xml');

            const xmlResult = xmlToJson(xmlDoc) as ArrayOfUplouderResponseXml;
            this.uploaderResponse = Array.isArray(xmlResult.ArrayOfUplouderResponse.UplouderResponse)
                ? xmlResult.ArrayOfUplouderResponse.UplouderResponse
                : [xmlResult.ArrayOfUplouderResponse.UplouderResponse];
        } catch (error) {
            this.$snackbar.error('Nastala chyba při uploadu.');
        } finally {
            this.isUploading = false;

            this.files = [];
        }
    }

    async download(item: Soubor) {
        const resp = await this.service.fileDownload(item.cesta);
        const blob = new Blob([resp.data], { type: 'application/octet-stream' });
        const link = document.createElement('a');
        link.href = URL.createObjectURL(blob);
        link.download = item.nazev;
        link.click();
        URL.revokeObjectURL(link.href);
        link.remove();
    }

    remove(item: Soubor) {
        if (confirm('Opravdu chcete vymazat přílohu?')) {
            this.$emit('input', [...this.soubory.filter(f => f != item)]);
        }
    }
}
